import * as React from "react";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/globalStyle.scss";
import Home from "./Components/OfferListingPage";
import Footer from "./Components/Footer/footer";

const IndexPage = (props) => {
  return (
    <>
      <Helmet>
        <title>GradRight Group Offer</title>
        <link
        rel="stylesheet"
        href="https://npmcdn.com/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
      ></link>
      <link href="https://unpkg.com/bootstrap-table@1.20.2/dist/bootstrap-table.min.css" rel="stylesheet"/>

<script src="https://unpkg.com/bootstrap-table@1.20.2/dist/bootstrap-table.min.js"></script>
        <meta charset={"utf-8"} />
        <meta
          name={"description"}
          content="The hassle-free platform to find the 'best loan' for your Master's abroad."
        />
        <meta name={"format-detection"} content="telephone=no" />
        <meta name={"msapplication-tap-highlight"} content={"no"} />
        <meta
          name={"viewport"}
          content="user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1,width=device-width"
        />
        <meta property="og:title" content={`GradRight Group Offer`} />
        <meta
          property="og:description"
          content="The hassle-free platform to find the 'best loan' for your Master's abroad."
        />
        <meta
          property="og:url"
          content={`https://groupoffers.gradright.com/`}
        />
        <meta property="og:image" content="og-logo.png" />
      </Helmet>
      <Home {...props}/>
      <Footer />
    </>
  );
};

export default IndexPage;
